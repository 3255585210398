.child-card-container {
  max-width: 360px;
}

.highlighted-container {
  animation: highligh 1.5s;
}

@keyframes highligh {
  from {
    background-color: #ffde4d;
  }
  to {
    background-color: white;
  }
}
